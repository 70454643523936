<template>
  <div>
    <div class="container-fluid">
      <div class="row mb-4">
        <div class="col-auto my-auto">
          <button class="btn btn-outline-success" @click="previousPeriod">
            <i class="far fa-arrow-left fa-2x"></i>
          </button>
        </div>
        <div class="col my-auto text-center">
          <button
            class="btn btn-sm btn-success me-3"
            v-if="retreat == 'malta'"
            @click="retreat = 'uk'"
          >
            View UK
          </button>

          <button class="btn btn-sm btn-outline-success me-3" @click="print">
            <i class="far fa-print me-2"></i>Print
          </button>

          <button
            class="btn btn-sm btn-success"
            v-if="retreat == 'uk'"
            @click="retreat = 'malta'"
          >
            View Malta
          </button>
        </div>
        <div class="col-auto my-auto ms-auto">
          <button class="btn btn-outline-success" @click="nextPeriod">
            <i class="far fa-arrow-right fa-2x"></i>
          </button>
        </div>
      </div>
      <div class="row" id="print-section">
        <div class="col-md-3 mb-4" v-for="day in days" :key="day.id">
          <day-card
            :type="'housekeeping'"
            :day="day"
            :retreat="retreat"
          ></day-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DayCard from "./due-ins-partials/DayCard";

export default {
  data() {
    return {
      days: [],
      startDate: moment(),
      retreat: "uk"
    };
  },
  watch: {
    startDate: {
      handler: function(val, old) {
        if (val != old) {
          this.init();
        }
      }
    },
    retreat: {
      handler: function(val, old) {
        if (val != old) {
          this.init();
        }
      }
    }
  },
  methods: {
    print() {
      console.log("printing");
      window.print();
    },
    init() {
      this.days = [];
      let daysRequired = 6;

      let startingOn = this.startDate.clone();

      console.log(startingOn);

      for (let i = 0; i <= daysRequired; i++) {
        var data = {
          id: i,
          title: moment(startingOn)
            .add(i, "days")
            .format("dddd"),
          date: moment(startingOn).add(i, "days")
        };
        this.days.push(data);
      }
    },
    previousPeriod() {
      var og = this.startDate;
      this.startDate = moment(og).subtract("7", "days");
      // this.$nextTick(() => {
      //   this.init();
      // });
    },
    nextPeriod() {
      var og = this.startDate;
      this.startDate = moment(og).add("7", "days");
      // this.$nextTick(() => {
      //   this.init();
      // });
    }
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.init();
  },
  components: {
    DayCard
  }
};
</script>

<style>
.floating-buttons {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
}

@media print {
  div {
    float: none !important;
    position: static !important;
    top: 0 !important;
    /* display: inline; */
    /* box-sizing: content-box !important; */
    /* flex: none !important;
    flex-wrap: unset !important;
    flex-direction: unset !important;
    height: auto; */
  }

  #app * {
    visibility: hidden;
  }
  #print-section,
  #print-section * {
    visibility: visible;
    float: none;
  }
  #print-section {
    position: fixed;
    left: 0;
    top: 0;
  }
}
</style>